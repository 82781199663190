import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../views/LandingPage.vue";
import GasStations from "../views/GasStations.vue";
import BlogPage from "../views/BlogPage.vue";
import SingleBlog from "../views/SingleBlog.vue";
import SingleProduct from "../views/SingleProduct.vue";
import OpenPositions from "../views/OpenPositions.vue";
import SingleJob from "../views/SingleJob.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import AboutCookies from "../views/AboutCookies.vue";
import ContactInformations from "../views/ContactInformations.vue";
import EPKlub from "../views/EPKlub.vue";
import LionExpert from "../views/LionExpert.vue";
import Services from "../views/Services.vue";
import ProductsPage from "../views/ProductsPage.vue";
import LionExpertGorivo from "../views/LionExpertGorivo.vue";
import { trackRouter } from "vue-gtag-next";
import QualityPolicy from "../views/QualityPolicy.vue";
import DownloadAppPage from "../views/DownloadAppPage.vue";
const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/benzinske-stanice",
    name: "GasStations",
    component: GasStations,
  },
  {
    path: "/benzinske-stanice/:serviceID",
    name: "ServiceGasStations",
    component: GasStations,
  },
  {
    path: "/najnovije-vesti",
    name: "BlogPage",
    component: BlogPage,
  },
  {
    path: "/najnovije-vesti/:slug",
    name: "SingleBlog",
    component: SingleBlog,
  },

  {
    path: "/lion-expert/proizvodi",
    name: "ProductsPage",
    component: ProductsPage,
  },

  // {
  //   path: "/lion-expert/gorivo",
  //   name: "LionExpertGorivo",
  //   component: LionExpertGorivo,
  // },

  {
    path: "/lion-expert/proizvodi/:slug",
    name: "SingleProduct",
    component: SingleProduct,
  },
  {
    path: "/otvorene-pozicije",
    name: "OpenPositions",
    component: OpenPositions,
  },
  {
    path: "/otvorene-pozicije/:slug",
    name: "SingleJob",
    component: SingleJob,
  },
  {
    path: "/zastita-podataka",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/euro-petrol-klub",
    name: "EPKlub",
    component: EPKlub,
  },
  {
    path: "/pravila-i-uslovi-koriscenja",
    name: "AboutCookies",
    component: AboutCookies,
  },
  {
    path: "/kontakt-informacije",
    name: "ContactInformations",
    component: ContactInformations,
  },
  // {
  //   path: "/lion-expert",
  //   name: "LionExpert",
  //   component: LionExpert,
  // },
  {
    path: "/usluge",
    name: "Services",
    component: Services,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },

  {
    path: "/politika-kvaliteta",
    name: "QualityPolicy",
    component: QualityPolicy,
  },
  {
    path: "/mobile-app",
    name: "Mobile App",
    component: DownloadAppPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

trackRouter(router);

export default router;
